import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import axios from 'axios';
import textos from '../textos/features.json';
import { API_URL } from '../utils/constants';

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [language, setLanguage] = useState('en');
  const [countryCode, setCountryCode] = useState('US');
  const [ip, setIp] = useState('');
  const [texts, setTexts] = useState({});

  const items = [
    {
      icon: <ViewQuiltRoundedIcon />,
      imageLight: 'url("/static/images/templates/dash-light.png")',
      imageDark: 'url("/static/images/templates/dash-dark.png")',
    },
    {
      icon: <EdgesensorHighRoundedIcon />,
      imageLight: 'url("/static/images/templates/mobile-light.png")',
      imageDark: 'url("/static/images/templates/mobile-dark.png")',
    },
    {
      icon: <DevicesRoundedIcon />,
      imageLight: 'url("/static/images/templates/devices-light.png")',
      imageDark: 'url("/static/images/templates/devices-dark.png")',
    },
  ];

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip))
      .catch(error => console.error('Error al obtener la IP:', error));
  }, []);

  useEffect(() => {
    const fetchCountryAndLanguage = async () => {
      if (!ip) return;

      try {
        const userLanguage = navigator.language.startsWith('es') ? 'es' : 'en';
        // const response = await axios.get(`${API_URL}/cotizaciones/getcountry/${ip}`);
        // const data = response.data;
        setCountryCode('US');

        setLanguage(userLanguage);

        const countryTexts = textos['US'] ? textos['US'][userLanguage] : textos['US'][userLanguage];
        setTexts(countryTexts);

      } catch (error) {
        console.error("Error fetching country data", error);
        setTexts(textos['US'][language]);
      }
    };

    fetchCountryAndLanguage();
  }, [ip]);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = texts.items ? texts.items[selectedItemIndex] : {};

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              {texts.title}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              {texts.description}
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {texts.items && texts.items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Leer más</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {texts.items && texts.items.map(({ title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.light'
                            : 'grey.200';
                        }
                        return selectedItemIndex === index ? 'primary.light' : 'grey.800';
                      },
                    }}
                  >
                    {items[index].icon}
                  </Box>
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{ color: 'text.primary' }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="regular"
                      sx={{ color: 'text.secondary' }}
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              width: '100%',
              minHeight: 300,
              mt: 1,
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}