import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import faqTexts from '../textos/faq.json'; // Asegúrate de que la ruta sea correcta
import { API_URL } from '../utils/constants';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);
  const [language, setLanguage] = useState('en');
  const [countryCode, setCountryCode] = useState('US');
  const [ip, setIp] = useState('');
  const [texts, setTexts] = useState({
    title: '',
    description: '',
    items: [],
  });

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip))
      .catch(error => console.error('Error al obtener la IP:', error));
  }, []);

  useEffect(() => {
    const fetchCountryAndLanguage = async () => {
      if (!ip) return;

      try {
        const userLanguage = navigator.language.startsWith('es') ? 'es' : 'en';
        // const response = await axios.get(`${API_URL}/cotizaciones/getcountry/${ip}`);
        // const data = response.data;
        setCountryCode('US');

        setLanguage(userLanguage);

        const countryTexts = faqTexts['US'] ? faqTexts['US'][userLanguage] : faqTexts['US'][userLanguage];
        setTexts(countryTexts);

        console.log(countryTexts);
      } catch (error) {
        console.error("Error fetching country data", error);
        setTexts(faqTexts['US'][language]);
      }
    };

    fetchCountryAndLanguage();
  }, [ip]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        {texts.title || 'Preguntas frecuentes'}
      </Typography>
      <Box sx={{ width: '100%' }}>
        {texts.items.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}d-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
