import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/X';
import { validateEmail } from '../utils';
import { API_URL } from '../utils/constants';
import { AlertSuccess, AlertError } from '../utils/alerts';

import axios from 'axios';
import textos from '../textos/footer.json'; // Asegúrate de que la ruta sea correcta

const logoStyle = {
  width: '50px',
  height: 'auto',
};

function Copyright() {
  return (
    <>
      <Typography variant="body2" color="text.secondary" mt={1}>
        {'Copyright © '}
        <Link href="">Devssoft&nbsp;</Link>
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="text.secondary" mt={1}>
        {'Bolivia'}
      </Typography>
    </>
  );
}

export default function Footer() {

  const [email, setEmail] = useState('');
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [language, setLanguage] = useState('en');
  const [countryCode, setCountryCode] = useState('US');
  const [ip, setIp] = useState('');
  const [texts, setTexts] = useState({
    title: '',
    description: '',
    items: [],
  });

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip))
      .catch(error => console.error('Error al obtener la IP:', error));
  }, []);

  useEffect(() => {
    const fetchCountryAndLanguage = async () => {
      if (!ip) return;

      try {
        const userLanguage = navigator.language.startsWith('es') ? 'es' : 'en';
        // const response = await axios.get(`${API_URL}/cotizaciones/getcountry/${ip}`);
        // const data = response.data;
        setCountryCode('US');

        setLanguage(userLanguage);

        const countryTexts = textos['US'] ? textos['US'][userLanguage] : textos['US'][userLanguage];
        setTexts(countryTexts);

      } catch (error) {
        console.error("Error fetching country data", error);
        setTexts(textos['US'][language]);
      }
    };

    fetchCountryAndLanguage();
  }, [ip]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // Aquí puedes realizar la solicitud a la API con el correo electrónico
    // Por ejemplo, utilizando fetch o axios
    fetch(API_URL + '/newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(response => {
        if (response.ok) {
          // Si la solicitud fue exitosa, puedes manejar la respuesta aquí
          console.log('Suscrito correctamente');
          setEmailEnviado(true);
          setEmailError(false);
          setEmail('');
          // También puedes realizar acciones adicionales, como mostrar un mensaje de éxito al usuario
        } else {
          // Maneja errores de la solicitud aquí
          setEmailEnviado(false);
          setEmailError(true);
          console.error('El correo ya se encuentra suscrito');
        }
      })
      .catch(error => {
        console.error('Error en la solicitud:', error);
      });
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ ml: '-15px' }}>
              <img
                src={
                  require('../img/logo.png')
                }
                style={logoStyle}
                alt="logo of Devssoft"
              />
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              {texts.newsletterTitle}
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {texts.newsletterDescription}
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label={texts.emailPlaceholder}
                placeholder={texts.emailPlaceholder}
                value={email}
                onChange={handleEmailChange}
                inputProps={{
                  autoComplete: 'off',
                }}
              />
              <Button 
                variant="contained" 
                color="primary" 
                sx={{ flexShrink: 0 }}
                disabled={!validateEmail(email)}
                onClick={handleSubmit}
              >
                {texts.subscribeButton}
              </Button>
            </Stack>
            <Stack>
              {!emailError && emailEnviado && <AlertSuccess message={texts.successMessage} />}
              {!emailEnviado && emailError && <AlertError message={texts.errorMessage} />}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href={texts.facebookLink}
            target="_blank"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href={texts.linkedinLink}
            target="_blank"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
