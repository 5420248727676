import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import axios from 'axios';
import textos from '../textos/highlights.json'; // Asegúrate de que la ruta sea correcta
import { API_URL } from '../utils/constants';

export default function Highlights() {
  const [language, setLanguage] = useState('en');
  const [countryCode, setCountryCode] = useState('US');
  const [ip, setIp] = useState('');
  const [texts, setTexts] = useState({
    title: '',
    description: '',
    items: [],
  });

  const icons = [
    <SettingsSuggestRoundedIcon />,
    <ConstructionRoundedIcon />,
    <ThumbUpAltRoundedIcon />,
    <AutoFixHighRoundedIcon />,
    <SupportAgentRoundedIcon />,
    <QueryStatsRoundedIcon />,
  ];

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip))
      .catch(error => console.error('Error al obtener la IP:', error));
  }, []);

  useEffect(() => {
    const fetchCountryAndLanguage = async () => {
      if (!ip) return;

      try {
        const userLanguage = navigator.language.startsWith('es') ? 'es' : 'en';
        // const response = await axios.get(`${API_URL}/cotizaciones/getcountry/${ip}`);
        // const data = response.data;
        setCountryCode('US');

        setLanguage(userLanguage);

        const countryTexts = textos['US'] ? textos['US'][userLanguage] : textos['US'][userLanguage];
        setTexts(countryTexts);

      } catch (error) {
        console.error("Error fetching country data", error);
        setTexts(textos['US'][language]);
      }
    };

    fetchCountryAndLanguage();
  }, [ip]);

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            {texts.title}
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            {texts.description}
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {texts.items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{icons[index]}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
